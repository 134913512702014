.notification-icon-animation {
  animation: bell-animation 0.5s ease infinite alternate;
}

@keyframes bell-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(10deg);
  }
}

.notifications-wrapper {
  position: relative;
  transition: transform 0.4s ease;
}

.notifications-wrapper-closed {
  position: relative;
  transform: translate(-160px, -15px);
  transition: transform 0.3s ease;
}

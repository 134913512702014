.div-flex {
    display: flex;
    align-items: center;
    gap: 15px;
}

.div-flex-bigger-gap {
    display: flex;
    align-items: center;
    gap: 20px;
}

.div-flex-simple {
    display: flex;
    gap: 15px;
}

.div-flex-column {
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.div-flex-space-between {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
}

.hidden {
    visibility: hidden;
}

@media (max-width: 1100px) {
    .div-flex-responsive-1100 {
        flex-wrap: wrap
    }
}

@media (max-width: 1200px) {
    .div-flex-responsive-1200 {
        flex-wrap: wrap
    }
}

@media (max-width: 1800px) {
    .div-flex-responsive-1800 {
        flex-wrap: wrap
    }
}

@media (min-width: 1200px) {
    .no-display-responsive {
        display: none!important;
    }
}

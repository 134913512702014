.loader {
    width: 25px;
    height: 25px;
    border: 4px solid #FFF;
    border-bottom-color: #E0726E;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.fill-space {
    width: 25px;
    height: 25px;
    display: block;
}
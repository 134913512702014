@import "../../styles/fullCalendar/colors";

button[class^="materialui-daterange-picker-makeStyles-filled"],
button[class*=" materialui-daterange-picker-makeStyles-filled"] {
  background-color: $orange !important;
}

button[class*="materialui-daterange-picker-makeStyles-outlined"] {
  border: 1px solid $orange !important;
}

div[class*="materialui-daterange-picker-MuiInput-underline"] {
  &:before {
    border-bottom: unset!important;
  }

  &:after {
    border-bottom: unset!important;
  }
}

svg[class*="materialui-daterange-picker-MuiSelect-icon"] {
  top: calc(50% - 11px)!important;
}

div[class*="materialui-daterange-picker-MuiInputBase-input"] {
  padding-top: 7px!important;
  padding-bottom: 7px!important;
}

div[class*="materialui-daterange-picker-makeStyles-weekDaysContainer"] {
  margin-top: 5px!important;
}

div[class*="materialui-daterange-picker-makeStyles-daysContainer"] {
  margin-top: 10px!important;
}
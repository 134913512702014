//remove shadow when a button clicked
.fc-button-primary {
  &:focus {
    box-shadow: none!important;
  }
}

//remove border when a button clicked
.fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active {
  &:active {
    border: none;
  }
}

.evenlargerDistanceBetweenElements {
  margin-top: 7rem;
}

.largerDistanceBetweenElements {
  margin-top: 1.5rem;
}

.standardDistanceBetweenElements {
  margin-top: 1rem;
}

.smallDistanceBetweenElements {
  margin-top: 0.5rem;
}

@import "colors";

.fc .fc-button-group .fc-next-button {
  background-color: white;
  border: none;
  color: $grey;
  font-size: 1.1em;
  margin-right: 20px;
}

.fc .fc-button .fc-icon {
  font-size: 1em;
  font-weight: 600;
}
@import "colors";

.fc .fc-button-group .fc-today-button {
  background-color: white;
  border: 1px solid $orange;
  border-radius: 5px!important;
  color: $orange;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  &:disabled {
    cursor: default;
  }
}

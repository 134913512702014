@import "fullCalendar/prevArrowButton";
@import "fullCalendar/nextArrowButton";
@import "fullCalendar/buttonPrimary";
@import "fullCalendar/todayButton";
@import "fullCalendar/toolbarTitle";
@import "fullCalendar/colors";
@import "fullCalendar/dayGridMonthButton";
@import "fullCalendar/dayGridWeekButton";
@import "fullCalendar/dayGridDayButton";
@import "fullCalendar/listMonthButton";
@import "fullCalendar/dayGridActiveButton";
@import "fullCalendar/daygridDay";
@import "fullCalendar/dayGridDayToday";
@import "fullCalendar/border";
@import "fullCalendar/customLoader";

.fc {
  font-size: 14px!important;
  margin: 20px 16px 0;
}

.fc .fc-view-harness {
  height: 73vh!important;
}

.fc .fc-daygrid-day-frame {
  cursor: pointer;
}

//days padding
.fc .fc-col-header-cell-cushion {
  padding: 2px 4px 15px 4px;
}

.fc .fc-daygrid-body {
  height: 100%;
}

.fc .fc-daygrid-body .fc-scrollgrid-sync-table {
  height: 100%!important;
  width: 100%!important;
}

.fc .fc-daygrid-body {
  width: 100%!important;
}

.fc .fc-scrollgrid-section table {
  width: 100%!important;
}

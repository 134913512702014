.wrapper-coach-corner-user {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.wrapper-logo-select {
    display: flex;
    align-items: center;
    padding: 10px 0 14px 2px;
}

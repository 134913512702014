//My styles//

.introjs-tooltip {
  display: flex;
  flex-direction: column;
  min-width: 25rem;
  border-radius: 10px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.introjs-tooltip-header {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  padding: 1.2rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.introjs-tooltiptext {
  color: #181818;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  text-align: justify;
  padding: 1.2rem;
}

.introjs-tooltip-title {
  font-size: 20px;
  vertical-align: middle;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.introjs-tooltip img {
  vertical-align: middle;
}

.introjs-skipbutton {
  margin-top: 0.75rem;
  padding-right: 1.2rem;
  text-align: center;
  vertical-align: middle;
  font-size: medium;
  text-transform: uppercase;
  letter-spacing: normal;
  color: #a0a0a0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.introjs-skipbutton:hover {
  color: black;
}

.introjs-progress {
  background-color: #eeeeee;
}

.introjs-progressbar {
  background-color: #e0726e;
}

.introjs-button {
  letter-spacing: normal;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  word-spacing: normal;
  line-height: normal;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  background-color: white;
  color: #e0726e;
  border: 1px solid #e0726e;
  border-radius: 5px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.introjs-button:hover {
  background-color: #e0726e29;
  color: #e0726e;
  border: 1px solid #ef6c00;
}

.introjs-button:focus {
  background-color: #e0726e29;
  border: 1px solid #e0726e;
  color: #e0726e;
  outline: 2px solid #e0726e;
  outline-offset: 0.05rem;
}

.introjs-disabled {
  color: #e0726e29;
  border-color: #e0726e29;
  box-shadow: none;
  cursor: default;
  background-color: white;
  background-image: none;
  text-decoration: none;
}

.introjs-disabled:hover {
  color: #e0726e29;
  border-color: #e0726e29;
  box-shadow: none;
  cursor: default;
  background-color: white;
  background-image: none;
  text-decoration: none;
}

.introjs-disabled:focus {
  color: #e0726e29;
  border-color: #e0726e29;
  box-shadow: none;
  cursor: default;
  background-color: white;
  background-image: none;
  text-decoration: none;
  outline: none;
  outline-offset: 0;
}

.fc-theme-standard td, .fc-theme-standard tr th {
  border-right: none;
}

.fc-theme-standard td, .fc-theme-standard th {
  border-right: 1px solid var(--fc-border-color, #ddd);
}

.fc .fc-scrollgrid-section-body table, .fc .fc-scrollgrid-section-footer table {
  border-left: 1px solid var(--fc-border-color, #ddd);
}

.fc-theme-standard .fc-col-header-cell{
  border:none;
  border-bottom: 1px solid var(--fc-border-color, #ddd);
}

.fc-daygrid-day {
  border: 1px solid var(--fc-border-color, #ddd);
}

.fc-theme-standard .fc-scrollgrid {
  border:none;
}
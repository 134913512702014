.user-info {
    width: 136px;
    overflow-y: auto;
    flex-direction: column;
}

.user-info-name {
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px;
    font-size: 14px;
    color: #555555;
}

.user-info-email {
    margin: 5px 0 0 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px;
    font-size: 9px;
    color: #555555;
}

@import "colors";

.fc .fc-button-group .fc-customLoader-button {
  background-color: white;
  border: none;
  margin-left: 15px!important;
  padding: 0;
  color: $orange;
  cursor: default;
  &:disabled {
    cursor: default;
  }
}
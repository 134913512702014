@import "colors";

.fc .fc-daygrid-day.fc-day-today {
  background-color: white;
}

.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-top .fc-daygrid-day-number {
  background-color: $orange;
  border-radius: 100%;
  width: 28px;
  height: 28px;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
